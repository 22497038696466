import React, { Component } from 'react';

class EmptyCard extends Component {
  render() {
    return (
      <div className="p-1 w-36 h-24  ">

      </div>
    );
  }
}

export default EmptyCard;
