import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div className="flex p-2 text-xs justify-center">

      </div>
    );
  }
}

export default Footer;
